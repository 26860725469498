import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="h-full flex flex-wrap content-center items-center">
      <div className="mx-auto text-center">
        <h1 className="text-9xl text-gray-400 font-extralight">404</h1>
        <p className="py-6 text-gray-400">Found, this page was not.</p>
      </div>
    </div>
    
  </Layout>
)

export default NotFoundPage
